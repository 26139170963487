




































import { MutationPayload } from 'vuex';
import { productInteraction } from '@/api/products';
import Modal from '@/components/modal.vue';
import ProductCard from '@/partials/ProductCard.vue';
import store from '@/store';
import Textarea from '@/components/form/textarea.vue';
import { coverLetterConfig, productDetailModal } from './index';
import { defineComponent, reactive } from '@vue/composition-api';
import useContext from '@/composition/context';
import router from '@/router';

export default defineComponent({
  components: {
    Modal,
    Textarea,
    ProductCard,
  },
  setup() {
    const { contextVisitor, translations } = useContext();

    const state = reactive({
      loading: false,
      coverLetterConfig: coverLetterConfig(),
    });

    const coverLetter = '';

    const modal = productDetailModal;

    const interact = () => {
      const productId = parseInt(router.currentRoute.params.product, 10);

      if (contextVisitor.value.id) {
        const interaction = {
          product: productId,
          visitor: contextVisitor.value.id,
          comment: coverLetter !== '' ? coverLetter : undefined,
        };

        state.loading = true;

        productInteraction(interaction)
          .then(() => {
            state.loading = false;
            store.commit('addPopup', {
              message: translations.value.visitors.exhibitors.successfully_submited,
              type: 'success',
              autohide: true,
            });
            if (modal && modal.close) {
              modal.close();
            }
          })
          .catch((err) => {
            state.loading = false;
            const data = err.response.data;
            if (data._errors && data._errors.length > 0) {
              data._errors.forEach((error: { message: string }) => {
                store.commit('addPopup', {
                  message: error.message,
                  type: 'danger',
                  autohide: true,
                });
              });
            } else {
              store.commit('addPopup', {
                message: err.message,
                type: 'danger',
                autohide: true,
              });
            }
          });
      }
    };

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        state.coverLetterConfig = coverLetterConfig();
      }
    });

    return { state, coverLetter, interact, modal, translations };
  },
});
